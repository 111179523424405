<template>
  <div class="box">
    <el-form :model="info" label-position="right" :rules="rules" ref="ruleForm" label-width="100px" class="ruleForm" size="small">
      <el-form-item label="用户名" prop="username">
        <el-input v-model="info.username" placeholder="请输入用户名"></el-input>
      </el-form-item>
      <el-form-item label="昵称" prop="nickname">
        <el-input v-model="info.nickname" placeholder="请输入昵称"></el-input>
      </el-form-item>
      <el-form-item label="邮箱">
        <el-input v-model="info.email" placeholder="请输入邮箱"></el-input>
      </el-form-item>
      <el-form-item label="密码">
        <el-input show-password v-model="info.password" placeholder="请输入密码"></el-input>
      </el-form-item>
      <el-form-item label="确认密码">
        <el-input show-password v-model="info.password2" placeholder="请输入确认密码"></el-input>
      </el-form-item>
      <el-form-item label="角色" prop="groups">
        <el-select v-model="info.groups" placeholder="请选择">
          <el-option
                  v-for="item in roleList"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
        <el-button type="button" @click="reBack()">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>

export default {
  data() {
    return {
      roleList: [],
      info: {
        id: 0,
        groups: '',
        username: '',
        nickname: '',
        email: ''
      },
      source_domain: this.config.SOURCE_DOMAIN,
      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        nickname: [
          { required: true, message: '请输入昵称', trigger: 'blur' }
        ],
        groups: [
          { required: true, message: '请选择角色', trigger: 'blur' }
        ]
      }
    };
  },
  created() {
    this.getGroupList()
    if(this.$route.query.id) {
      this.info.id = this.$route.query.id
      this.getInfo()
    }
  },
  mounted() {
    //
  },
  computed: {
  },
  methods: {
    getGroupList() {
      var that = this
      this.$api.merchant.groupIndex({page_size: 100},function (res) {
        if(res.errcode == 0) {
          that.roleList = res.data.data
        }
      })
    },
    getInfo() {
      var that = this
      this.$api.merchant.userDetail({id: that.info.id}, function (res) {
        if(res.errcode == 0) {
          that.info = res.data
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    submitForm(formName) {
      var that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var param = {
            id: that.info.id,
            username: that.info.username,
            nickname: that.info.nickname,
            email: that.info.email,
            groups: that.info.groups,
            password: that.info.password
          }
          if(that.info.password) {
            if(that.info.password.length<7) {
              that.fail('密码长度不能小于6位，请重新填写')
              return false
            } else if(that.info.password2 != that.info.password) {
              that.fail('密码输入不一致，请重新填写')
              return false
            }
          } else if(this.info.id == 0) {
            that.fail('密码不能为空，请填写密码')
            return false
          }
          if(this.info.id == 0) {
            this.$api.merchant.userAdd(param,function (res) {
              if(res.errcode == 0) {
                that.success(res.errmsg)
                that.reBack()
              } else {
                that.fail(res.errmsg)
              }
            })
          } else {
            this.$api.merchant.userEdit(param,function (res) {
              if(res.errcode == 0) {
                that.success(res.errmsg)
                that.reBack()
              } else {
                that.fail(res.errmsg)
              }
            })
          }
        } else {
          return false;
        }
      });
    },
  }
};
</script>
<style scoped>
  .box {
    background-color: #fff;
    padding: 20px;
    border-radius: 6px;
    min-height: 500px;
  }
  .ruleForm {
    width: 600px;
  }
</style>
